<template>
    <div class="container">
      <router-link to="/Shop" class="btn btn-link ps-0 mb-4 mt-4"><i class="bi bi-chevron-left"></i> Back to Shopping</router-link>
      <h4 class="fw-bold">Shopping Cart</h4>
      <span style="color:red;">All cart items will be emptied at midnight</span><br>

      <button type="button" class="btn btn-link ps-0 mb-2" style="font-size: 0.9em;" @click="deleteAllCartItems">Remove All Items</button>

      <div class="row">
        <div class="col col-lg-8 mt-2 pt-2">

          <div class="cart-item-desc border-bottom py-4" v-for="(item, index) in cartData" :key="item">
            <div class="row">
              <div class="col-4 col-sm-2">
                <div class="">
                  <img :src="imageUrl(item)" @error="imageUrlAlt" class="img-fluid">
                </div>
              </div>
              <div class="col-8 col-sm-3">
                <h5 class="text-nowrap">Item Number:{{item.Code}}</h5>
                <h4>{{item.Description}}</h4>
                <span class="d-block text-green fw-bold">In Stock</span>

              </div>
              <div class="col-6 col-sm-3 ms-5">
                <span class="d-block">Type: {{frameTypeDisplay(item)}}</span>
                <span class="d-block" v-html="details(item)"></span>
                <span class="d-block">Instructions: {{item.productSelection.instructions}}</span>
              </div>
              <div class="col-6 col-sm-3">
                <h5 class="fw-bold text-end">${{item.calc.totalPrice.toFixed(2)}}</h5>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-xs-6 col-lg-3 col-xl-3">
                <button type="button" class="btn btn-link ps-0" @click="deleteCartItem(index)">Remove</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-lg-4">

          <div class="bg-light mb-3 border-0 px-4 py-4">
            <div class="subtotal-body">
              <h6 class="text-uppercase fw-semibold mb-4">Order Summary</h6>
              <div class="d-flex justify-content-between">
                <div class="pb-2 small">Subtotal ({{totalItems}})</div>
                <div class="pb-2 fw-bold small">${{totalCartPrice}}</div>
              </div>
              <!--
              <div class="d-flex justify-content-between">
                <div class="pb-2 small">Shipping</div>
                <div class="pb-2 small">Calculated during checkout</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="pb-2 small">Tax</div>
                <div class="pb-2 small">Calculated during checkout</div>
              </div>
              -->
              <div class="border-bottom border-dark pb-3"></div>

              <div class="d-flex justify-content-between pt-3">
                <div class="pb-2 fw-bold small">Subtotal ({{totalItems}})</div>
                <div class="pb-2 fw-bold small">${{totalCartPrice}}</div>
              </div>

              <div class="d-flex justify-content-between pt-3">
                <div class="pb-2 fw-bold small">PO Number</div>
                <div class="pb-2 fw-bold small">
                  <input type="text" v-model="poNumber">
                </div>
              </div>

              <div class="d-grid gap-2 mt-4">
                <button class="btn btn-secondary fw-bold" type="button" v-show="showCheckoutBtn && totalCartItems > 0" @click.prevent="submitOrder">Submit Order</button>
              </div>
            </div>
          </div>

        </div>

      </div>


      <div class="d-grid gap-2 col-lg-3 mx-auto empty-state text-center mb-4 d-none">
        <h5>Your Shopping Cart is Empty</h5>
        <button type="button" class="btn btn-secondary">Browse the Shop</button>
      </div>

    </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "viewCart",
  data() {
    return {
      defaultImg: require("@/assets/images/img-default.png"),
      totalCartPrice:0,
      totalItems:0,
      poNumber:'',
      showCheckoutBtn: false
    }
  },
  computed: {
    ...mapGetters({
      'cartData': 'cart/getCart',
      'totalCartItems':'cart/getTotalCartItems'
    }),
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (!string || string.trim() === '') {
        return '';
      }
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    frameTypeDisplay(item){
      let disp = item.productSelection.type
      //if(item.FrameType === 'ACRYLIC' || item.FrameType === 'FOAMBOARD'){
      if(item.FrameType !== 'MOULDING'){
        disp = this.capitalizeFirstLetter(item.FrameType)
      }
      if(disp === 'exact'){
        disp = 'Straight Cut';
      }
      return disp
    },
    imageUrl(item) {
      let loc = process.env.VUE_APP_CDN + "/frames/" + item.Code + "/" + item.Code + "-small.jpg";
      if(item.FrameType === 'MATS'){
        loc = process.env.VUE_APP_CDN + "/mat/" + item.ManufCode + "/" + item.ManufCode + "-small.jpg";
      }
      return loc;
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg
    },
    isValid(value) {
      return value !== null && value !== "" && (!isNaN(value) ? Number(value) > 0 : true);
    },
    sanitizeValue(value) {
      return value === 0 || value === "0" ? "" : value;
    },
    details(item){
      let disp = ''
      //if((item.productSelection.type === 'length' || item.productSelection.type === 'box') && item.FrameType !== 'ACRYLIC' && item.FrameType !== 'FOAMBOARD') {
      if(item.FrameType === 'MOULDING') {
        if(item.productSelection.type === 'length' || item.productSelection.type === 'box'){
          disp += 'Length: ' + item.productSelection.length + ' (feet)';
          switch (item.backtype) {
            case 'backremq':
              disp += '<br>Back Ordered: '+item.backremq+'(feet)<br>';
              break;
            case 'backallq':
              disp += '<br>Back Ordered: '+item.backallq+'(feet)<br>';
              break;
            }
        }else{
          disp += 'Quantity: '+item.productSelection.quantity+'<br>'

          if (
              (this.isValid(item.productSelection.dimension.length) || this.isValid(item.productSelection.dimension.length_fraction)) &&
              (this.isValid(item.productSelection.dimension.width) || this.isValid(item.productSelection.dimension.width_fraction))
          ) {
            disp += 'Dimensions:<br>' +
                this.sanitizeValue(item.productSelection.dimension.length) + ' ' +
                this.sanitizeValue(item.productSelection.dimension.length_fraction);
            disp += ' x ' +
                this.sanitizeValue(item.productSelection.dimension.width) + ' ' +
                this.sanitizeValue(item.productSelection.dimension.width_fraction);
          } else if (this.isValid(item.productSelection.dimension.length) || this.isValid(item.productSelection.dimension.length_fraction)) {
            disp += 'Dimensions:<br>' +
                this.sanitizeValue(item.productSelection.dimension.length) + ' ' +
                this.sanitizeValue(item.productSelection.dimension.length_fraction) + ' (Rail)';
          } else if (this.isValid(item.productSelection.dimension.width) || this.isValid(item.productSelection.dimension.width_fraction)) {
            disp += 'Dimensions:<br>' +
                this.sanitizeValue(item.productSelection.dimension.width) + ' ' +
                this.sanitizeValue(item.productSelection.dimension.width_fraction) + ' (Rail)';
          }

          /*
          if(item.productSelection.dimension.length > 0 || item.productSelection.dimension.length_fraction > 0 &&
              item.productSelection.dimension.width > 0 || item.productSelection.dimension.width_fraction > 0
          ){
            disp += 'Dimensions:<br>'+item.productSelection.dimension.length+' '+item.productSelection.dimension.length_fraction
            disp += ' x '+item.productSelection.dimension.width+' '+item.productSelection.dimension.width_fraction
          }else if(item.productSelection.dimension.length > 0 || item.productSelection.dimension.length_fraction){
            disp += 'Dimensions:<br>'+item.productSelection.dimension.length+' '+item.productSelection.dimension.length_fraction+' (Rail)'
          }else if(item.productSelection.dimension.width > 0 || item.productSelection.dimension.width_fraction > 0){
            disp += 'Dimensions:<br>'+item.productSelection.dimension.width+' '+item.productSelection.dimension.width_fraction+' (Rail)'
          }
          */

        }
      }else{
        disp += 'Quantity: '+item.productSelection.quantity+'<br>'
      }
      return disp
    },
    submitOrder(){
        let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: this.canCancel,
            color: this.color,
            backgroundColor: this.bgColor,
            height: this.height,
            width: this.width,
            loader: this.loader,
            opacity: 0.3,
        });

      this.$store.dispatch('cart/completeOrder').then(data => {
        if(data.orderNumber){
          this.$router.push({name: 'OrderDetails', params:{orderid:data.orderNumber}})
        }else{
          alert('There was a error please refresh the page and try again');
        }
        loader.hide();
      })
    },
    calcTotalItems(){
      this.totalItems = this.totalCartItems == 1 ? this.totalCartItems+' item' : this.totalCartItems !== undefined ? this.totalCartItems+' items' : ''
    },
    calcTotal(){
      this.totalCartPrice = 0
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.cartData).forEach(([key,value]) => {
        this.totalCartPrice = (parseFloat(this.totalCartPrice) + parseFloat(value.calc.totalPrice))
      });
      this.totalCartPrice = this.totalCartPrice.toFixed(2)
      this.calcTotalItems()
    },
    deleteCartItem(index){
      let newCart = this.cartData
      newCart.splice(index, 1);

      this.$store.dispatch('cart/updateCartData',newCart).then(() => {
        this.calcTotal()
      })

    },
    deleteAllCartItems(){
      let newCart = {}
      this.$store.dispatch('cart/updateCartData',newCart).then(() => {
        this.calcTotal()
      })

    }
  },
  async updated() {
    await this.$store.dispatch('userAccess/isAuthenticated');
    this.showCheckoutBtn = true
    localStorage.setItem('lastCartActivityTime', Date.now());
  },
  mounted() {
    this.$store.dispatch('cart/getCartData',{}).then(() => {
      this.calcTotal()
    })

  }
}
</script>

<style scoped>

</style>